body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{
  text-decoration: none;
}

/* .displaysum {
  display:flex;
} */
/* .displaysum :hover {
  color: #000099;
  width: auto;
  height: auto;
  transition: 0.3s  all ease;

} */
/* .displaysum {
  background: linear-gradient(to right, #003366 0%, #000099 100%);

  margin: 1rem;
  transition: all 0.5s ease;
  transform: scale(1);
}
 
.displaysum button:hover {
  cursor: pointer;
  transform: scale(1.25);
  filter: brightness(120%);
} */

.cs_center{
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: gray;
  z-index: 0;
}
.cs_sm_icon{
  cursor:pointer;
  transition: all .2s ease-in-out;

}
.cs_sm_icon:hover {
  transform: scale(1.1);
  box-shadow: rgba(2, 38, 66, 0.3) 0px 1px 2px 0px, rgba(1, 27, 46, 0.15) 0px 1px 3px 1px;

}

.cs_l_d_green {
  background: linear-gradient(to top, #006600 0%, #669900 100%);
}
.cs_l_d_blue {
  background: linear-gradient(to right, #003366 0%, #000099 100%);
}
.cs_l_d_red {
  background: linear-gradient(to left, #800000 0%, #990033 100%);
}
.cs_toast{
  color: white;
  background: linear-gradient(to left, #faf9f5 0%, #b97003 50%);
}

.cs_d_icon {
  font-size: 12vw;
    position: absolute;
    margin-left: 10px;
  /* margin-right: 300px; */
  color: white;
  opacity: 0.1;
  z-index: 0;
}
.cs_d_txt {
  font-size: 7vw;
  font-weight: bold;
  color: white;
  z-index: 1;
}

@media screen and (max-width: 576px) {
  .cs_d_icon {
    font-size: 20vh;
    margin-left: 0px;
    margin-right: 10px;

  }
  .cs_d_txt {
    font-size: 100px;
  }  
}